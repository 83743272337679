import React from "react";

import Layout from "../layout/Layout";

import About from "../components/sections/About";
import Seo from "../components/seo";

const AboutPage = () => {
  return (
    <Layout>
      <Seo title="About" />
      <About />
    </Layout>
  );
};

export default AboutPage;
