import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { MeetArtist } from "../../../Buttons";

const TheArtist = () => {
  return (
    <div>
      <div className="relative grid lg:grid-cols-2 place-items-center mx-auto py-16">
        <div className="h-full flex flex-col gap-4 items-center justify-center py-8">
          <p className="mobile-title lg:desktop-title text-center max-w-md mx-auto ml-auto">
            The Artist
          </p>
          <p className="mobile-paragraph lg:desktop-paragraph max-w-sm lg:max-w-lg text-center">
            This painintg is called
            <strong> The Ovary Twins in Retirement </strong>and it began in 2018
            when I realised it had been one year since my last menstrual cycle
            (I was 57). I completed it this week, when I painted in the red
            lips, to mark the completion of the a 3.5 year menopausal hell ride!
            jejeje....
          </p>
          <a href="https://www.barefootartes.com/dana-gallery">
            <MeetArtist />
          </a>
          <div className="absolute top-0 -left-64 ">
            <StaticImage
              height={521}
              src="../../../../images/BackgroundElements/Home/Banana-01.png"
              alt="bananas"
              placeholder="tracedSVG"
            />
          </div>
        </div>
        <div className="h-full flex gap-4 items-center justify-center lg:py-8 lg:pr-8 mx-auto scale-75 transform -translate-y-12 lg:translate-y-0 lg:scale-100 ">
          <StaticImage
            width={606}
            height={424}
            src="../../../../images/Picture/AboutArtist.png"
            placeholder="tracedSVG"
            alt="Artist"
          />
        </div>
      </div>
    </div>
  );
};

export default TheArtist;
