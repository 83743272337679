import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import TheArtist from "../widgets/TheArtist"

const About = () => {
  return (
    <div>
      {/* Mobile */}
      <div className="relative right-0 left-0 mx-auto flex flex-col items-center max-w-mobile gap-4 z-10 lg:hidden">
        <h1 className="mobile-title pb-2">About Dana</h1>
        <p className="mobile-paragraph">
          Dana Kopfer Maxey has spent her entire life working in various aspects
          of the Arts and is a sojourner on the Eastern Mystical Path. She leads
          workshops in many forms of yoga and energy medicine. Her yoga practice
          incorporates Kriya, Kundalini and Hatha forms, as well as her new
          passion, Qigong. She teaches Breath and Transformation classes, as
          well as self-healing through connecting to our own inner physician.
        </p>

        <p className="z-10">
          As an artist she is a theatre director and is the founder of{" "}
          <strong>THE VENUS PROEJCTS</strong>: a woman’s collective of
          performing artists in San Pancho, Mexico. Her emphasis is on opening
          our voices and telling our stories. Each production is a process,
          exploring self, fear and inhibitions, connecting to our voice,
          writing, movement as medicine and performance. She is also a
          performing musician, playwright and painter, and what-ever else shows
          up in which to make a joyful MESS.
        </p>

        <p className="mobile-paragraph">
          She lives between Ketchum Idaho and San Pancho, Mexico with her
          husband, artist, BBQ BOB Maxey.
        </p>

        <p className="mobile-paragraph">
          The Juicy Pause Podcast is her foray into meeting a larger audience,
          hosting interviews, on our experiences as we walk, run and sometimes
          stumble on the Camino to becoming a Wisdom Woman.
        </p>
        <div className="my-8 border-transparent">
          <StaticImage
            height={490}
            src="../../../images/Picture/AboutDana.JPG"
            alt="Dana"
            placeholder="tracedSVG"
          />
        </div>
      </div>
      <div className="absolute top-1/3 -left-72 z-0 lg:hidden">
        <StaticImage
          height={490}
          src="../../../images/BackgroundElements/Home/Lemondrop-01.png"
          alt="Lemon"
          placeholder="tracedSVG"
        />
      </div>

      {/* Desktop */}
      <div className="hidden lg:block min-h-screen">
        <h1 className="desktop-title text-center py-8">About Dana</h1>
        <div className="flex items-center  justify-center gap-8 h-full  px-2">
          <div className="max-w-3xl flex flex-col gap-4 z-10">
            <p className="desktop-paragraph">
              Dana Kopfer Maxey has spent her entire life working in various
              aspects of the Arts and is a sojourner on the Eastern Mystical
              Path. She leads workshops in many forms of yoga and energy
              medicine. Her yoga practice incorporates Kriya, Kundalini and
              Hatha forms, as well as her new passion, Qigong. She teaches
              Breath and Transformation classes, as well as self-healing through
              connecting to our own inner physician.
            </p>
            <p className="desktop-paragraph z-10">
              As an artist she is a theatre director and is the founder of{" "}
              <strong>THE VENUS PROEJCTS</strong>: a woman’s collective of
              performing artists in San Pancho, Mexico. Her emphasis is on
              opening our voices and telling our stories. Each production is a
              process, exploring self, fear and inhibitions, connecting to our
              voice, writing, movement as medicine and performance. She is also
              a performing musician, playwright and painter, and what-ever else
              shows up in which to make a joyful MESS.
            </p>

            <p className="desktop-paragraph">
              She lives between Ketchum Idaho and San Pancho, Mexico with her
              husband, artist, BBQ BOB Maxey.
            </p>

            <p className="desktop-paragraph">
              The Juicy Pause Podcast is her foray into meeting a larger
              audience, hosting interviews, on our experiences as we walk, run
              and sometimes stumble on the Camino to becoming a Wisdom Woman.
            </p>
          </div>
          <div className="flex-shrink-0 scale-90 mb-auto xl:scale-100">
            <StaticImage
              height={707}
              width={472}
              src="../../../images/Picture/AboutDana.JPG"
              alt="Dana"
              placeholder="tracedSVG"
            />
          </div>
          <div className="absolute -top-16 -left-48 z-0 hidden lg:block">
            <StaticImage
              height={490}
              src="../../../images/BackgroundElements/Home/Lemondrop-01.png"
              alt="Lemon"
              placeholder="tracedSVG"
            />
          </div>
        </div>
      </div>
      <TheArtist />
    </div>
  )
}

export default About
